import { call, put, select, takeEvery } from 'redux-saga/effects';
import {
    ACTIVATION_TRAILERS_REQUEST,
    ActivationTrailersRequestActionT,
    ADD_TRAILER_REQUEST,
    AddTrailerRequestActionT,
    ARCHIVE_TRAILERS_REQUEST,
    ArchiveTrailersRequestActionT,
    ASSIGN_TO_CONTRACT_TRAILERS_REQUEST,
    AssignToContractRequestActionT,
    DELETE_TRAILERS_REQUEST,
    DeleteTrailersRequestActionT,
    EXPORT_XLS_TRAILERS_REQUEST,
    ExportXLSTrailersActionT,
    FETCH_TRAILER_DETAILS_REQUEST,
    FETCH_TRAILERS_PAGE_REQUEST,
    FETCH_TRAILERS_STATS_REQUEST,
    FetchTrailerDetailsActionT,
    FetchTrailersPageActionT,
    FetchTrailersStatsActionT,
    IMPORT_XLS_TRAILERS_REQUEST,
    ImportXLSTrailersActionT,
    UPDATE_TRAILERS_REQUEST,
    UpdateTrailerActionT,
    CHANGE_RATE_TRAILERS_REQUEST,
    ChangeRateTrailersRequestActionT,
} from './types';
import {
    addTrailerRequestBegin,
    addTrailerRequestError,
    addTrailerRequestSuccess,
    deleteTrailersRequestBegin,
    deleteTrailersRequestError,
    deleteTrailersRequestSuccess,
    exportXLSTrailersRequestBegin,
    exportXLSTrailersRequestError,
    exportXLSTrailersRequestSuccess,
    fetchTrailerDetails,
    fetchTrailerDetailsRequestBegin,
    fetchTrailerDetailsRequestError,
    fetchTrailerDetailsRequestSuccess,
    fetchTrailersPageBegin,
    fetchTrailersPageError,
    fetchTrailersPageSuccess,
    fetchTrailersStatsRequestBegin,
    fetchTrailersStatsRequestError,
    fetchTrailersStatsRequestSuccess,
    importXLSTrailersRequestBegin,
    importXLSTrailersRequestError,
    importXLSTrailersRequestSuccess,
    resetTrailers,
    updateTrailersRequestBegin,
    updateTrailersRequestError,
    updateTrailersRequestSuccess,
} from './actions';
import {
    selectFetchTrailersStatsRequestStatus,
    selectTrailersByIds,
    selectTrailersDetailsByIds,
    selectTrailersPages,
    selectTrailersQuery,
} from './selectors';
import checkNeedRequest from 'common/utils/check-need-request';
import brokerTranziitApi from 'broker-admin/utils/api/broker-tranziit/api';
import { checkIsDefaultCompanyId } from 'common/utils';
import carrierTranziitApi from 'carrier/utils/api/carrier-tranziit/api';
import { checkIsSameQuery, checkShouldEmitChangePage } from 'common/utils/pagination/utils';
import { addAlert } from 'common/store/alerts/actions';
import { CommonAlertTypeEnum, CommonAnyAlert } from 'common/components/toasts/AlertToastsManager/models';
import { trailersPaginationChannel, trailersRefreshChannel } from 'common/store/trailers/channels';
import { CompanyTrailersStatsT } from 'common/store/trailers/models';
import downloadFile from 'common/utils/download-file';
import moment from 'moment';
import { ApiArchiveVehiclesRequestT } from 'common/utils/api/models';

function* fetchTrailersSaga(action: FetchTrailersPageActionT): WrapGeneratorT<void> {
    const { pageNumber, query, options, companyId } = action;

    const prevQuery: ReReturnT<typeof selectTrailersQuery> = yield select(selectTrailersQuery(companyId));
    const pages: ReReturnT<typeof selectTrailersPages> = yield select(selectTrailersPages(companyId));
    const isSameQuery = checkIsSameQuery(query, prevQuery);
    const isNeedRequest = checkNeedRequest(pages[pageNumber]?.requestStatus, options);

    if (isSameQuery && !isNeedRequest) {
        return;
    }
    if (!isSameQuery) {
        yield put(resetTrailers(companyId, { savingPageNumber: pageNumber }));
    }

    yield put(fetchTrailersPageBegin(query, pageNumber, companyId));

    let result: ReturnApiT<
        typeof brokerTranziitApi.fetchTrailersPage | typeof carrierTranziitApi.fetchTrailersPage
    > | null = null;
    if (checkIsDefaultCompanyId(companyId)) {
        result = yield carrierTranziitApi.fetchTrailersPage({
            ...query,
            page: pageNumber,
        });
    } else {
        result = yield brokerTranziitApi.fetchTrailersPage(
            {
                ...query,
                page: pageNumber,
            },
            companyId,
        );
    }

    if (!result) {
        return;
    }

    const [error, response] = result;
    if (error) {
        yield put(fetchTrailersPageError(query, pageNumber, error, companyId));
        return;
    }

    if (!response) {
        return;
    }

    yield put(fetchTrailersPageSuccess(query, pageNumber, companyId, response));

    checkShouldEmitChangePage(pageNumber, response, trailersPaginationChannel);
}

function* refreshTrailerDetailsIfNeedSaga(companyId: CompanyIdT, trailerId: TrailerIdT): WrapGeneratorT<void> {
    const detailsByIds: ReReturnT<typeof selectTrailersDetailsByIds> = yield select(
        selectTrailersDetailsByIds(companyId),
    );

    if (detailsByIds[trailerId]) {
        yield put(fetchTrailerDetails(trailerId, companyId, { isForceUpdate: true }));
    }
}

function* addTrailerSaga(action: AddTrailerRequestActionT): WrapGeneratorT<void> {
    const { data, companyId } = action;

    yield put(addTrailerRequestBegin(companyId));

    let result: ReturnApiT<
        typeof brokerTranziitApi.fetchTrailersPage | typeof carrierTranziitApi.fetchTrailersPage
    > | null = null;
    if (checkIsDefaultCompanyId(companyId)) {
        result = yield carrierTranziitApi.addTrailer(data);
    } else {
        result = yield brokerTranziitApi.addPartnerTrailer(data, companyId);
    }

    if (!result) {
        return;
    }
    const [error] = result;
    if (error) {
        yield put(addTrailerRequestError(error, companyId));
    } else {
        yield put(addTrailerRequestSuccess(companyId));
    }

    if (!error) {
        yield put(
            addAlert(
                new CommonAnyAlert({
                    type: CommonAlertTypeEnum.trailerAdded,
                    data: {
                        plateNumber: data.plateNumber || '',
                    },
                }),
            ),
        );
    }

    trailersRefreshChannel.emit({});
}

function* deleteTrailersSaga(action: DeleteTrailersRequestActionT): WrapGeneratorT<void> {
    const { trailerIds, companyId } = action;

    yield put(deleteTrailersRequestBegin(companyId));

    let error: ReturnApiT<typeof brokerTranziitApi.deleteTrailer | typeof carrierTranziitApi.deleteTrailer>[0] | null =
        null;

    for (const trailerId of trailerIds) {
        let result: ReturnApiT<
            typeof brokerTranziitApi.deleteTrailer | typeof carrierTranziitApi.deleteTrailer
        > | null = null;
        if (checkIsDefaultCompanyId(companyId)) {
            result = yield carrierTranziitApi.deleteTrailer(trailerId);
        } else {
            result = yield brokerTranziitApi.deleteTrailer(companyId, trailerId);
        }

        error = error || result?.[0] || null;
    }

    if (error) {
        yield put(deleteTrailersRequestError(error, companyId));
    } else {
        yield put(deleteTrailersRequestSuccess(companyId));
    }

    trailersRefreshChannel.emit({});
}

function* activateTrailersSaga(action: ActivationTrailersRequestActionT): WrapGeneratorT<void> {
    const { activateTrailersRequest, companyId } = action;

    yield put(updateTrailersRequestBegin(companyId));

    let response: ReturnApiT<
        typeof brokerTranziitApi.activateTrailers | typeof carrierTranziitApi.activateTrailers
    > | null = null;

    if (checkIsDefaultCompanyId(companyId)) {
        response = yield carrierTranziitApi.activateTrailers(activateTrailersRequest);
    } else {
        response = yield brokerTranziitApi.activateTrailers(companyId, activateTrailersRequest);
    }

    const error = response?.[0];
    const result = response?.[1];

    if (error) {
        yield put(updateTrailersRequestError(error, companyId));
    } else {
        yield put(updateTrailersRequestSuccess(companyId));
    }

    const trailerIds = activateTrailersRequest?.ids || [];
    const allCount = trailerIds.length;
    const invalidAssetsMap = result?.invalidAssets || {};
    const failedTrailerIds = Object.keys(invalidAssetsMap);
    const failedCount = failedTrailerIds.length;
    const successCount = allCount - failedCount;

    const byId: ReReturnT<typeof selectTrailersByIds> = yield select(selectTrailersByIds(companyId));

    if (successCount === 1) {
        const trailer = byId[trailerIds[0]];

        yield put(
            addAlert(
                new CommonAnyAlert({
                    type: CommonAlertTypeEnum.trailerSuccessfullyActivated,
                    data: {
                        plateNumber: trailer?.plateNumber || '',
                    },
                }),
            ),
        );
    }

    if (successCount > 1) {
        yield put(
            addAlert(
                new CommonAnyAlert({
                    type: CommonAlertTypeEnum.trailersSuccessfullyActivated,
                    data: {
                        count: successCount,
                    },
                }),
            ),
        );
    }

    if (failedCount === 1) {
        const trailer = byId[trailerIds[0]];

        yield put(
            addAlert(
                new CommonAnyAlert({
                    type: CommonAlertTypeEnum.trailerFailedActivate,
                    data: {
                        plateNumber: trailer?.plateNumber || '',
                    },
                }),
            ),
        );
    }

    if (failedCount > 1) {
        const invalidAssetsPlateNumbers = failedTrailerIds.map((trailerId) => {
            return byId[trailerId]?.plateNumber || '';
        });

        yield put(
            addAlert(
                new CommonAnyAlert({
                    type: CommonAlertTypeEnum.trailersFailedActivate,
                    data: {
                        count: failedCount,
                        plateNumbers: invalidAssetsPlateNumbers,
                    },
                }),
            ),
        );
    }

    trailersRefreshChannel.emit({});

    for (const trailerId of trailerIds) {
        yield call(refreshTrailerDetailsIfNeedSaga, companyId, trailerId);
    }
}

function* assignToContractSaga(action: AssignToContractRequestActionT): WrapGeneratorT<void> {
    const { contractName, changeContractRequest, companyId } = action;

    yield put(updateTrailersRequestBegin(companyId));

    let response: ReturnApiT<
        typeof brokerTranziitApi.assignTrailersToContract | typeof carrierTranziitApi.assignTrailersToContract
    > | null = null;
    if (checkIsDefaultCompanyId(companyId)) {
        response = yield carrierTranziitApi.assignTrailersToContract(changeContractRequest);
    } else {
        response = yield brokerTranziitApi.assignTrailersToContract(companyId, changeContractRequest);
    }

    const error = response?.[0];
    const result = response?.[1];

    if (error) {
        yield put(updateTrailersRequestError(error, companyId));
    } else {
        yield put(updateTrailersRequestSuccess(companyId));
    }

    const trailerIds = changeContractRequest?.ids || [];
    const allCount = trailerIds.length;
    const successCount = result?.changed || 0;
    const failedCount = allCount - successCount;

    const byId: ReReturnT<typeof selectTrailersByIds> = yield select(selectTrailersByIds(companyId));

    if (successCount === 1) {
        const trailer = byId[trailerIds[0]];

        yield put(
            addAlert(
                new CommonAnyAlert({
                    type: CommonAlertTypeEnum.trailerSuccessfullyUpdatedContract,
                    data: {
                        plateNumber: trailer?.plateNumber || '',
                        contractName,
                    },
                }),
            ),
        );
    }

    if (successCount > 1) {
        yield put(
            addAlert(
                new CommonAnyAlert({
                    type: CommonAlertTypeEnum.trailersSuccessfullyUpdatedContract,
                    data: {
                        count: successCount,
                        contractName,
                    },
                }),
            ),
        );
    }

    if (failedCount === 1) {
        const trailer = byId[trailerIds[0]];

        yield put(
            addAlert(
                new CommonAnyAlert({
                    type: CommonAlertTypeEnum.trailerFailedUpdateContract,
                    data: {
                        plateNumber: trailer?.plateNumber || '',
                        contractName,
                    },
                }),
            ),
        );
    }

    if (failedCount > 1) {
        const invalidAssetsPlateNumbers = trailerIds.map((trailerId) => {
            return byId[trailerId]?.plateNumber || '';
        });

        yield put(
            addAlert(
                new CommonAnyAlert({
                    type: CommonAlertTypeEnum.trailersFailedUpdateContract,
                    data: {
                        count: failedCount,
                        plateNumbers: invalidAssetsPlateNumbers,
                        contractName,
                    },
                }),
            ),
        );
    }

    trailersRefreshChannel.emit({});

    for (const trailerId of trailerIds) {
        yield call(refreshTrailerDetailsIfNeedSaga, companyId, trailerId);
    }
}

function* changeRateTrailersSaga(action: ChangeRateTrailersRequestActionT): WrapGeneratorT<void> {
    const { changeRateVehiclesRequest, companyId } = action;

    yield put(updateTrailersRequestBegin(companyId));

    let response: ReturnApiT<
        typeof brokerTranziitApi.changeRateTrailers | typeof carrierTranziitApi.changeRateTrailers
    > | null = null;
    if (checkIsDefaultCompanyId(companyId)) {
        response = yield carrierTranziitApi.changeRateTrailers(changeRateVehiclesRequest);
    } else {
        response = yield brokerTranziitApi.changeRateTrailers(companyId, changeRateVehiclesRequest);
    }

    const error = response?.[0];
    const result = response?.[1];

    if (error) {
        yield put(updateTrailersRequestError(error, companyId));
    } else {
        yield put(updateTrailersRequestSuccess(companyId));
    }

    const trailerIds = changeRateVehiclesRequest.ids || [];
    const allCount = trailerIds.length;
    const successCount = result?.changed || 0;
    const failedCount = allCount - successCount;

    const byId: ReReturnT<typeof selectTrailersByIds> = yield select(selectTrailersByIds(companyId));

    if (successCount === 1) {
        const trailer = byId[trailerIds[0]];

        yield put(
            addAlert(
                new CommonAnyAlert({
                    type: CommonAlertTypeEnum.trailerSuccessfullyUpdatedRate,
                    data: {
                        plateNumber: trailer?.plateNumber || '',
                    },
                }),
            ),
        );
    }

    if (successCount > 1) {
        yield put(
            addAlert(
                new CommonAnyAlert({
                    type: CommonAlertTypeEnum.trailersSuccessfullyUpdatedRate,
                    data: {
                        count: successCount,
                    },
                }),
            ),
        );
    }

    if (failedCount === 1) {
        const trailer = byId[trailerIds[0]];

        yield put(
            addAlert(
                new CommonAnyAlert({
                    type: CommonAlertTypeEnum.trailerFailedUpdateRate,
                    data: {
                        plateNumber: trailer?.plateNumber || '',
                    },
                }),
            ),
        );
    }

    if (failedCount > 1) {
        const invalidAssetsPlateNumbers = trailerIds.map((trailerId) => {
            return byId[trailerId]?.plateNumber || '';
        });

        yield put(
            addAlert(
                new CommonAnyAlert({
                    type: CommonAlertTypeEnum.trailersFailedUpdateRate,
                    data: {
                        count: failedCount,
                        plateNumbers: invalidAssetsPlateNumbers,
                    },
                }),
            ),
        );
    }

    trailersRefreshChannel.emit({});

    for (const trailerId of trailerIds) {
        yield call(refreshTrailerDetailsIfNeedSaga, companyId, trailerId);
    }
}

function* archiveTrailersSaga(action: ArchiveTrailersRequestActionT): WrapGeneratorT<void> {
    const { trailerIds, companyId, reason } = action;

    yield put(updateTrailersRequestBegin(companyId));

    const data: ApiArchiveVehiclesRequestT = { ids: trailerIds, comment: reason };

    let response: ReturnApiT<
        typeof brokerTranziitApi.archiveTrailers | typeof carrierTranziitApi.archiveTrailers
    > | null = null;
    if (checkIsDefaultCompanyId(companyId)) {
        response = yield carrierTranziitApi.archiveTrailers(data);
    } else {
        response = yield brokerTranziitApi.archiveTrailers(companyId, data);
    }

    const error = response?.[0];
    if (error) {
        yield put(updateTrailersRequestError(error, companyId));
    } else {
        yield put(updateTrailersRequestSuccess(companyId));
    }

    const result = response?.[1];

    const allCount = trailerIds.length;
    const invalidAssetsMap = result?.invalidAssets || {};
    const failedTrailerIds = Object.keys(invalidAssetsMap);
    const failedCount = failedTrailerIds.length;
    const successCount = allCount - failedCount;

    const byId: ReReturnT<typeof selectTrailersByIds> = yield select(selectTrailersByIds(companyId));

    if (successCount === 1) {
        const trailer = byId[trailerIds[0]];

        yield put(
            addAlert(
                new CommonAnyAlert({
                    type: CommonAlertTypeEnum.trailerSuccessfullyArchived,
                    data: {
                        plateNumber: trailer?.plateNumber || '',
                    },
                }),
            ),
        );
    }

    if (successCount > 1) {
        yield put(
            addAlert(
                new CommonAnyAlert({
                    type: CommonAlertTypeEnum.trailersSuccessfullyArchived,
                    data: {
                        count: successCount,
                    },
                }),
            ),
        );
    }

    if (failedCount === 1) {
        const trailer = byId[failedTrailerIds[0]];

        yield put(
            addAlert(
                new CommonAnyAlert({
                    type: CommonAlertTypeEnum.trailerFailedArchived,
                    data: {
                        plateNumber: trailer?.plateNumber || '',
                    },
                }),
            ),
        );
    }

    if (failedCount > 1) {
        const invalidAssetsPlateNumbers = failedTrailerIds.map((trailerId) => {
            return byId[trailerId]?.plateNumber || '';
        });

        yield put(
            addAlert(
                new CommonAnyAlert({
                    type: CommonAlertTypeEnum.trailersFailedArchived,
                    data: {
                        count: trailerIds.length,
                        plateNumbers: invalidAssetsPlateNumbers,
                    },
                }),
            ),
        );
    }

    trailersRefreshChannel.emit({});

    for (const trailerId of trailerIds) {
        yield call(refreshTrailerDetailsIfNeedSaga, companyId, trailerId);
    }
}

function* fetchTrailersStatsSaga(action: FetchTrailersStatsActionT): WrapGeneratorT<void> {
    const { companyId, options } = action;

    const fetchStatsRequestStatus: ReReturnT<typeof selectFetchTrailersStatsRequestStatus> = yield select(
        selectFetchTrailersStatsRequestStatus(companyId),
    );

    const isNeedRequest = checkNeedRequest(fetchStatsRequestStatus, options);
    if (!isNeedRequest) {
        return;
    }

    yield put(fetchTrailersStatsRequestBegin(companyId));

    let error: Error | null = null;
    let stats: CompanyTrailersStatsT | null = null;
    if (checkIsDefaultCompanyId(companyId)) {
        const result: ReturnApiT<typeof carrierTranziitApi.fetchTrailersActiveFleetCount> =
            yield carrierTranziitApi.fetchTrailersActiveFleetCount();
        const [resultError, data] = result;
        if (resultError) {
            error = resultError;
        }
        if (data) {
            stats = {
                actualTotalCount: data,
            };
        }
    } else {
        const result: ReturnApiT<typeof brokerTranziitApi.fetchTrailersActiveFleetCount> =
            yield brokerTranziitApi.fetchTrailersActiveFleetCount(companyId);
        const [resultError, data] = result;
        if (resultError) {
            error = resultError;
        }
        if (data) {
            stats = {
                actualTotalCount: data,
            };
        }
    }

    if (error) {
        yield put(fetchTrailersStatsRequestError(error, companyId));
        return;
    }

    yield put(fetchTrailersStatsRequestSuccess(stats, companyId));
}

function* fetchTrailerDetailsSaga(action: FetchTrailerDetailsActionT): WrapGeneratorT<void> {
    const { trailerId, companyId, options } = action;

    const detailsById: ReReturnT<typeof selectTrailersDetailsByIds> = yield select(
        selectTrailersDetailsByIds(companyId),
    );
    if (detailsById[trailerId] && !options?.isForceUpdate) {
        return;
    }

    yield put(fetchTrailerDetailsRequestBegin(companyId));

    let result: ReturnApiT<
        typeof brokerTranziitApi.fetchTrailerDetails | typeof carrierTranziitApi.fetchTrailerDetails
    > | null = null;
    if (checkIsDefaultCompanyId(companyId)) {
        result = yield carrierTranziitApi.fetchTrailerDetails(trailerId);
    } else {
        result = yield brokerTranziitApi.fetchTrailerDetails(companyId, trailerId);
    }
    if (!result) {
        return;
    }

    const [error, details] = result;
    if (error) {
        yield put(fetchTrailerDetailsRequestError(error, companyId));
        return;
    }

    yield put(fetchTrailerDetailsRequestSuccess(trailerId, details, companyId));
}

function* updateTrailerSaga(action: UpdateTrailerActionT): WrapGeneratorT<void> {
    const { companyId, trailerId, updateTrailer } = action;

    yield put(updateTrailersRequestBegin(companyId));

    let result: ReturnApiT<typeof brokerTranziitApi.updateTrailer | typeof carrierTranziitApi.updateTrailer> | null =
        null;
    if (checkIsDefaultCompanyId(companyId)) {
        result = yield carrierTranziitApi.updateTrailer(trailerId, updateTrailer);
    } else {
        result = yield brokerTranziitApi.updateTrailer(companyId, trailerId, updateTrailer);
    }
    if (!result) {
        return;
    }
    const [error] = result;

    if (error) {
        yield put(updateTrailersRequestError(error, companyId));
    } else {
        yield put(updateTrailersRequestSuccess(companyId));
    }

    if (!error) {
        const detailsById: ReReturnT<typeof selectTrailersDetailsByIds> = yield select(
            selectTrailersDetailsByIds(companyId),
        );
        const trailer = detailsById[trailerId];

        yield put(
            addAlert(
                new CommonAnyAlert({
                    type: CommonAlertTypeEnum.trailerSuccessfullyEdited,
                    data: {
                        plateNumber: trailer?.plateNumber || '',
                    },
                }),
            ),
        );
    }

    trailersRefreshChannel.emit({});

    yield put(fetchTrailerDetails(trailerId, companyId, { isForceUpdate: true }));
}

function* importXLSTrailersSaga(action: ImportXLSTrailersActionT): WrapGeneratorT<void> {
    const { companyId, file } = action;

    yield put(importXLSTrailersRequestBegin(companyId));

    let result: ReturnApiT<
        typeof brokerTranziitApi.importPartnerXLSTrucks | typeof carrierTranziitApi.importXLSTrucks
    > | null = null;
    if (checkIsDefaultCompanyId(companyId)) {
        result = yield carrierTranziitApi.importXLSTrailers(file);
    } else {
        result = yield brokerTranziitApi.importPartnerXLSTrailers(companyId, file);
    }
    if (!result) {
        return;
    }
    const [error] = result;

    if (error) {
        yield put(importXLSTrailersRequestError(error, companyId));
    } else {
        yield put(importXLSTrailersRequestSuccess(companyId));
    }
}

function* exportXLSTrailersSaga(action: ExportXLSTrailersActionT): WrapGeneratorT<void> {
    const { companyId, query } = action;

    yield put(exportXLSTrailersRequestBegin(companyId));

    let result: ReturnApiT<
        typeof brokerTranziitApi.exportPartnerXLSTrailers | typeof carrierTranziitApi.exportXLSTrailers
    > | null = null;
    if (checkIsDefaultCompanyId(companyId)) {
        result = yield carrierTranziitApi.exportXLSTrailers(query);
    } else {
        result = yield brokerTranziitApi.exportPartnerXLSTrailers(companyId, query);
    }
    if (!result) {
        return;
    }
    const [error, data] = result;

    if (data) {
        downloadFile({
            name: `trailers-export-${moment().format('YYYY-MM-DD_HH:mm')}.xlsx`,
            data,
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
    }

    if (error) {
        yield put(exportXLSTrailersRequestError(error, companyId));
    } else {
        yield put(exportXLSTrailersRequestSuccess(companyId));
    }
}

function* trailersSaga(): WrapGeneratorT<void> {
    yield takeEvery(FETCH_TRAILERS_PAGE_REQUEST, fetchTrailersSaga);
    yield takeEvery(ADD_TRAILER_REQUEST, addTrailerSaga);
    yield takeEvery(DELETE_TRAILERS_REQUEST, deleteTrailersSaga);
    yield takeEvery(ACTIVATION_TRAILERS_REQUEST, activateTrailersSaga);
    yield takeEvery(ARCHIVE_TRAILERS_REQUEST, archiveTrailersSaga);
    yield takeEvery(ASSIGN_TO_CONTRACT_TRAILERS_REQUEST, assignToContractSaga);
    yield takeEvery(CHANGE_RATE_TRAILERS_REQUEST, changeRateTrailersSaga);
    yield takeEvery(FETCH_TRAILER_DETAILS_REQUEST, fetchTrailerDetailsSaga);
    yield takeEvery(UPDATE_TRAILERS_REQUEST, updateTrailerSaga);
    yield takeEvery(FETCH_TRAILERS_STATS_REQUEST, fetchTrailersStatsSaga);
    yield takeEvery(IMPORT_XLS_TRAILERS_REQUEST, importXLSTrailersSaga);
    yield takeEvery(EXPORT_XLS_TRAILERS_REQUEST, exportXLSTrailersSaga);
}

export default trailersSaga;
