import * as React from 'react';
import cs from 'classnames';
import classNames from 'classnames/bind';
import styles from './TourTypeTabs.scss';
import Money from 'common/components/Money/Money';
import { CurrencyEnum, StyleGuideColorsEnum, UNIT, UnitTypeEnum } from 'common/constants';
import { useTranslation } from 'react-i18next';
import { TourTypeTabsEnum } from 'broker-admin/layouts/DispatchesPage/DispatchAssignmentPage/AssignmentForm/TourTypeTabs/constants';
import MoneyDiff from 'common/components/MoneyDiff/MoneyDiff';
import ColoredDiff from 'common/components/ColoredDiff/ColoredDiff';
import PillLabel, { PillLabelThemeEnum } from 'common/components/PillLabel/PillLabel';
import UnitTypeCount from 'common/components/units/UnitTypeCount/UnitTypeCount';
import isNumber from 'lodash/isNumber';
import { DispatchDetailsT } from 'broker-admin/store/dispatch-details/models';
import { SelfCostPredictionT } from 'broker-admin/store/dispatch-assigment/models';
import { getDiffPercent, getValuesDiff } from 'common/utils/percents';
import { moneyOperations } from 'common/utils/money';
import Tooltip, { TooltipPositionEnum, TooltipThemeEnum } from 'design-system/components/Tooltip/Tooltip';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';
import TooltipIconTrigger from 'design-system/components/Tooltip/TooltipIconTrigger/TooltipIconTrigger';

const cx = classNames.bind(styles);

type PropsT = {
    className?: string;
    dispatchDetails: DispatchDetailsT | null;
    selfCostPrediction?: SelfCostPredictionT | null;
    selectedTourTypeTab: TourTypeTabsEnum;
    onSelectTourTypeTab: (tourTab: TourTypeTabsEnum) => void;
};

const TourTypeTabs: React.FC<PropsT> = React.memo((props) => {
    const { className, selectedTourTypeTab, onSelectTourTypeTab, dispatchDetails, selfCostPrediction } = props;

    const estimateCost = dispatchDetails?.priceOffer?.totalPrice;
    const actualCost = selfCostPrediction?.calculatedSumCost?.totalCost;

    const nextCost = moneyOperations.notStrictPlus(
        selfCostPrediction?.truckNextTourOldCostDetails?.totalCost,
        selfCostPrediction?.trailerNextTourOldCostDetails?.totalCost,
        CurrencyEnum.EUR,
    );

    const actualNextCost = moneyOperations.notStrictPlus(
        selfCostPrediction?.truckNextTourNewCostDetails?.totalCost,
        selfCostPrediction?.trailerNextTourNewCostDetails?.totalCost,
        CurrencyEnum.EUR,
    );

    const costDiff = getValuesDiff(actualNextCost, nextCost);
    const actualNextPercentDiff = getDiffPercent(costDiff, nextCost);

    const { t } = useTranslation();

    const renderSafetyMoney = (amount: number | null | undefined) => {
        if (!isNumber(amount)) {
            return t('common:not-applicable');
        }

        return <Money amount={amount} currency={CurrencyEnum.EUR} />;
    };

    const isPositiveDiff = isNumber(costDiff) && costDiff < 0;

    return (
        <div className={cs(cx('wrap'), className)}>
            <div className={cx('columns')}>
                <div
                    className={cx('tab', {
                        'tab--isActive': selectedTourTypeTab === TourTypeTabsEnum.current,
                    })}
                    onClick={() => {
                        onSelectTourTypeTab(TourTypeTabsEnum.current);
                    }}
                >
                    <div className={cx('head')}>{t('assignment.tout-type-tabs.current-tour')}</div>
                    <MoneyDiff value={estimateCost} baseValue={estimateCost}>
                        {(diff) => (
                            <ColoredDiff isInverted diff={diff} customNegativeColor={StyleGuideColorsEnum.orange}>
                                <div className={cx('row', {})}>
                                    <div>{t('assignment.tout-type-tabs.estimate-tour-cost')}</div>
                                    <div>{renderSafetyMoney(estimateCost)}</div>
                                </div>
                            </ColoredDiff>
                        )}
                    </MoneyDiff>
                    <MoneyDiff value={actualCost} baseValue={estimateCost}>
                        {(diff) => (
                            <ColoredDiff isInverted diff={diff} customNegativeColor={StyleGuideColorsEnum.orange}>
                                <div className={cx('row')}>
                                    <div>{t('assignment.tout-type-tabs.actual-tour-cost')}</div>
                                    <div>{renderSafetyMoney(actualCost)}</div>
                                </div>
                            </ColoredDiff>
                        )}
                    </MoneyDiff>
                </div>
                <div
                    className={cx('tab', {
                        'tab--isActive': selectedTourTypeTab === TourTypeTabsEnum.next,
                    })}
                    onClick={() => {
                        onSelectTourTypeTab(TourTypeTabsEnum.next);
                    }}
                >
                    <div className={cx('head')}>
                        {t('assignment.tout-type-tabs.next-tour')}
                        <Tooltip
                            position={TooltipPositionEnum.topCenter}
                            theme={TooltipThemeEnum.black}
                            isInline
                            tooltipNode={
                                <TooltipContent width={50 * UNIT} theme={TooltipContentThemeEnum.black}>
                                    {t('assignment.tout-type-tabs.next-tour-tooltip-note')}
                                </TooltipContent>
                            }
                        >
                            {(isShow) => <TooltipIconTrigger isShow={isShow} />}
                        </Tooltip>
                    </div>
                    <MoneyDiff value={nextCost} baseValue={nextCost}>
                        {(diff) => (
                            <ColoredDiff isInverted diff={diff} customNegativeColor={StyleGuideColorsEnum.orange}>
                                <div className={cx('row')}>
                                    <div>{t('assignment.tout-type-tabs.current-tour-cost')}</div>
                                    <div>{renderSafetyMoney(nextCost)}</div>
                                </div>
                            </ColoredDiff>
                        )}
                    </MoneyDiff>
                    <MoneyDiff value={actualNextCost} baseValue={nextCost}>
                        {(diff) => (
                            <ColoredDiff isInverted diff={diff} customNegativeColor={StyleGuideColorsEnum.orange}>
                                <div className={cx('row')}>
                                    <div>{t('assignment.tout-type-tabs.actual-next-tour-cost')}</div>
                                    <div>{renderSafetyMoney(actualNextCost)}</div>
                                    {isNumber(actualNextPercentDiff) && (
                                        <div className={cx('diff-wrap')}>
                                            <PillLabel
                                                isSymmetrical
                                                className={cx('diff')}
                                                theme={
                                                    isPositiveDiff
                                                        ? PillLabelThemeEnum.brandDark
                                                        : PillLabelThemeEnum.orange
                                                }
                                            >
                                                {isPositiveDiff ? '-' : '+'}
                                                <UnitTypeCount
                                                    type={UnitTypeEnum.percentsAbbreviation}
                                                    count={actualNextPercentDiff}
                                                />
                                            </PillLabel>
                                        </div>
                                    )}
                                </div>
                            </ColoredDiff>
                        )}
                    </MoneyDiff>
                </div>
            </div>
        </div>
    );
});

export default TourTypeTabs;
