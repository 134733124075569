import { call, put, select, takeEvery } from 'redux-saga/effects';
import {
    ACTIVATION_TRUCKS_REQUEST,
    ActivationTrucksRequestActionT,
    ADD_TRUCK_REQUEST,
    AddTruckRequestActionT,
    ARCHIVE_TRUCKS_REQUEST,
    ArchiveTrucksRequestActionT,
    ASSIGN_TO_CONTRACT_TRUCKS_REQUEST,
    AssignToContractRequestActionT,
    DELETE_TRUCKS_REQUEST,
    DeleteTrucksRequestActionT,
    EXPORT_XLS_TRUCKS_REQUEST,
    FETCH_TRUCK_DETAILS_REQUEST,
    FETCH_TRUCKS_PAGE_REQUEST,
    FETCH_TRUCKS_STATS_REQUEST,
    FetchTruckDetailsActionT,
    FetchTrucksPageActionT,
    FetchTrucksStatsActionT,
    IMPORT_XLS_TRUCKS_REQUEST,
    ImportXLSTrucksActionT,
    UPDATE_TRUCK_REQUEST,
    UpdateTruckActionT,
    ExportXLSTrucksActionT,
    CHANGE_RATE_TRUCKS_REQUEST,
    ChangeRateTrucksRequestActionT,
} from './types';
import {
    addTruckRequestBegin,
    addTruckRequestError,
    addTruckRequestSuccess,
    deleteTrucksRequestBegin,
    deleteTrucksRequestError,
    deleteTrucksRequestSuccess,
    exportXLSTrucksRequestBegin,
    exportXLSTrucksRequestError,
    exportXLSTrucksRequestSuccess,
    fetchTruckDetails,
    fetchTruckDetailsRequestBegin,
    fetchTruckDetailsRequestError,
    fetchTruckDetailsRequestSuccess,
    fetchTrucksPageBegin,
    fetchTrucksPageError,
    fetchTrucksPageSuccess,
    fetchTrucksStatsRequestBegin,
    fetchTrucksStatsRequestError,
    fetchTrucksStatsRequestSuccess,
    importXLSTrucksRequestBegin,
    importXLSTrucksRequestError,
    importXLSTrucksRequestSuccess,
    resetTrucks,
    updateTrucksRequestBegin,
    updateTrucksRequestError,
    updateTrucksRequestSuccess,
} from './actions';
import {
    selectFetchTrucksStatsRequestStatus,
    selectTrucksByIds,
    selectTrucksDetailsByIds,
    selectTrucksPages,
    selectTrucksQuery,
} from './selectors';
import checkNeedRequest from 'common/utils/check-need-request';
import carrierTranziitApi from 'carrier/utils/api/carrier-tranziit/api';
import brokerTranziitApi from 'broker-admin/utils/api/broker-tranziit/api';
import { checkIsDefaultCompanyId } from 'common/utils';
import { checkIsSameQuery, checkShouldEmitChangePage } from 'common/utils/pagination/utils';
import { addAlert } from 'common/store/alerts/actions';
import { CommonAlertTypeEnum, CommonAnyAlert } from 'common/components/toasts/AlertToastsManager/models';
import { ApiTruckDetailsT, CompanyTrucksStatsT } from 'common/store/trucks/models';
import { trucksPaginationChannel, trucksRefreshChannel } from 'common/store/trucks/channels';
import downloadFile from 'common/utils/download-file';
import moment from 'moment';
import { ApiArchiveVehiclesRequestT } from 'common/utils/api/models';

function* fetchTrucksSaga(action: FetchTrucksPageActionT): WrapGeneratorT<void> {
    const { pageNumber, query, options, companyId } = action;

    const prevQuery: ReReturnT<typeof selectTrucksQuery> = yield select(selectTrucksQuery(companyId));
    const pages: ReReturnT<typeof selectTrucksPages> = yield select(selectTrucksPages(companyId));
    const isSameQuery = checkIsSameQuery(query, prevQuery);
    const isNeedRequest = checkNeedRequest(pages[pageNumber]?.requestStatus, options);
    if (isSameQuery && !isNeedRequest) {
        return;
    }
    if (!isSameQuery) {
        yield put(resetTrucks(companyId, { savingPageNumber: pageNumber }));
    }

    yield put(fetchTrucksPageBegin(query, pageNumber, companyId));

    let result: ReturnApiT<
        typeof brokerTranziitApi.fetchTrucksPage | typeof carrierTranziitApi.fetchTrucksPage
    > | null = null;
    if (checkIsDefaultCompanyId(companyId)) {
        result = yield carrierTranziitApi.fetchTrucksPage({
            ...query,
            page: pageNumber,
        });
    } else {
        result = yield brokerTranziitApi.fetchTrucksPage(
            {
                ...query,
                page: pageNumber,
            },
            companyId,
        );
    }
    if (!result) {
        return;
    }

    const [error, response] = result;

    if (error) {
        yield put(fetchTrucksPageError(query, pageNumber, error, companyId));
        return;
    }

    yield put(fetchTrucksPageSuccess(query, pageNumber, response, companyId));

    checkShouldEmitChangePage(pageNumber, response, trucksPaginationChannel);
}

function* refreshTruckDetailsIfNeedSaga(companyId: CompanyIdT, truckId: TruckIdT): WrapGeneratorT<void> {
    const detailsByIds: ReReturnT<typeof selectTrucksDetailsByIds> = yield select(selectTrucksDetailsByIds(companyId));

    if (detailsByIds[truckId]) {
        yield put(fetchTruckDetails(truckId, companyId, { isForceUpdate: true }));
    }
}

function* addTruckSaga(action: AddTruckRequestActionT): WrapGeneratorT<void> {
    const { data, companyId } = action;

    yield put(addTruckRequestBegin(companyId));

    let result: ReturnApiT<typeof brokerTranziitApi.addPartnerTruck | typeof carrierTranziitApi.addTruck> | null = null;
    if (checkIsDefaultCompanyId(companyId)) {
        result = yield carrierTranziitApi.addTruck(data);
    } else {
        result = yield brokerTranziitApi.addPartnerTruck(data, companyId);
    }

    if (!result) {
        return;
    }

    const [error] = result;
    if (error) {
        yield put(addTruckRequestError(error, companyId));
    } else {
        yield put(addTruckRequestSuccess(companyId));
    }

    if (!error) {
        yield put(
            addAlert(
                new CommonAnyAlert({
                    type: CommonAlertTypeEnum.truckAdded,
                    data: {
                        plateNumber: data.plateNumber || '',
                    },
                }),
            ),
        );
    }

    trucksRefreshChannel.emit({});
}

function* deleteTrucksSaga(action: DeleteTrucksRequestActionT): WrapGeneratorT<void> {
    const { truckIds, companyId } = action;

    yield put(deleteTrucksRequestBegin(companyId));

    let error: ReturnApiT<typeof brokerTranziitApi.deleteTruck | typeof carrierTranziitApi.deleteTruck>[0] | null =
        null;

    for (const truckId of truckIds) {
        let result: ReturnApiT<typeof brokerTranziitApi.deleteTruck | typeof carrierTranziitApi.deleteTruck> | null =
            null;
        if (checkIsDefaultCompanyId(companyId)) {
            result = yield carrierTranziitApi.deleteTruck(truckId);
        } else {
            result = yield brokerTranziitApi.deleteTruck(companyId, truckId);
        }

        error = error || result?.[0] || null;
    }

    if (error) {
        yield put(deleteTrucksRequestError(error, companyId));
    } else {
        yield put(deleteTrucksRequestSuccess(companyId));
    }

    trucksRefreshChannel.emit({});
}

function* activateTrucksSaga(action: ActivationTrucksRequestActionT): WrapGeneratorT<void> {
    const { activateTrucksRequest, companyId } = action;

    yield put(updateTrucksRequestBegin(companyId));

    let response: ReturnApiT<
        typeof brokerTranziitApi.activateTrucks | typeof carrierTranziitApi.activateTrucks
    > | null = null;
    if (checkIsDefaultCompanyId(companyId)) {
        response = yield carrierTranziitApi.activateTrucks(activateTrucksRequest);
    } else {
        response = yield brokerTranziitApi.activateTrucks(companyId, activateTrucksRequest);
    }

    const error = response?.[0];
    const result = response?.[1];
    if (error) {
        yield put(updateTrucksRequestError(error, companyId));
    } else {
        yield put(updateTrucksRequestSuccess(companyId));
    }

    const truckIds = activateTrucksRequest?.ids || [];
    const allCount = truckIds.length;
    const invalidAssetsMap = result?.invalidAssets || {};
    const failedTruckIds = Object.keys(invalidAssetsMap);
    const failedCount = failedTruckIds.length;
    const successCount = allCount - failedCount;

    const byId: ReReturnT<typeof selectTrucksByIds> = yield select(selectTrucksByIds(companyId));

    if (successCount === 1) {
        const truck = byId[truckIds[0]] || null;
        yield put(
            addAlert(
                new CommonAnyAlert({
                    type: CommonAlertTypeEnum.truckSuccessfullyActivated,
                    data: {
                        plateNumber: truck?.plateNumber || '',
                    },
                }),
            ),
        );
    }

    if (successCount > 1) {
        yield put(
            addAlert(
                new CommonAnyAlert({
                    type: CommonAlertTypeEnum.trucksSuccessfullyActivated,
                    data: {
                        count: successCount,
                    },
                }),
            ),
        );
    }

    if (failedCount === 1) {
        const truck = byId[failedTruckIds[0]] || null;

        yield put(
            addAlert(
                new CommonAnyAlert({
                    type: CommonAlertTypeEnum.truckFailedActivate,
                    data: {
                        plateNumber: truck?.plateNumber || '',
                    },
                }),
            ),
        );
    }

    if (failedCount > 1) {
        const invalidAssetsPlateNumbers = failedTruckIds.map((truckId) => {
            return byId[truckId]?.plateNumber || '';
        });

        yield put(
            addAlert(
                new CommonAnyAlert({
                    type: CommonAlertTypeEnum.trucksFailedActivate,
                    data: {
                        count: failedCount,
                        plateNumbers: invalidAssetsPlateNumbers,
                    },
                }),
            ),
        );
    }

    trucksRefreshChannel.emit({});

    for (const truckId of truckIds) {
        yield call(refreshTruckDetailsIfNeedSaga, companyId, truckId);
    }
}

function* archiveTrucksSaga(action: ArchiveTrucksRequestActionT): WrapGeneratorT<void> {
    const { truckIds, companyId, reason } = action;

    yield put(updateTrucksRequestBegin(companyId));

    const data: ApiArchiveVehiclesRequestT = { ids: truckIds, comment: reason };
    let response: ReturnApiT<typeof carrierTranziitApi.archiveTrucks | typeof brokerTranziitApi.archiveTrucks> | null =
        null;
    if (checkIsDefaultCompanyId(companyId)) {
        response = yield carrierTranziitApi.archiveTrucks(data);
    } else {
        response = yield brokerTranziitApi.archiveTrucks(companyId, data);
    }

    const error = response?.[0];
    if (error) {
        yield put(updateTrucksRequestError(error, companyId));
    } else {
        yield put(updateTrucksRequestSuccess(companyId));
    }

    const result = response?.[1];

    const allCount = truckIds.length;
    const invalidAssetsMap = result?.invalidAssets || {};
    const failedTruckIds = Object.keys(invalidAssetsMap);
    const failedCount = failedTruckIds.length;
    const successCount = allCount - failedCount;

    const byId: ReReturnT<typeof selectTrucksByIds> = yield select(selectTrucksByIds(companyId));

    if (successCount === 1) {
        const truck = byId[truckIds[0]];

        yield put(
            addAlert(
                new CommonAnyAlert({
                    type: CommonAlertTypeEnum.truckSuccessfullyArchived,
                    data: {
                        plateNumber: truck?.plateNumber || '',
                    },
                }),
            ),
        );
    }

    if (successCount > 1) {
        const truck = byId[truckIds[0]];

        yield put(
            addAlert(
                new CommonAnyAlert({
                    type: CommonAlertTypeEnum.trucksSuccessfullyArchived,
                    data: {
                        count: successCount,
                    },
                }),
            ),
        );
    }

    if (failedCount === 1) {
        const truck = byId[failedTruckIds[0]];

        yield put(
            addAlert(
                new CommonAnyAlert({
                    type: CommonAlertTypeEnum.truckFailedArchived,
                    data: {
                        plateNumber: truck?.plateNumber || '',
                    },
                }),
            ),
        );
    }

    if (failedCount > 1) {
        const invalidAssetsPlateNumbers = failedTruckIds.map((truckId) => {
            return byId[truckId]?.plateNumber || '';
        });

        yield put(
            addAlert(
                new CommonAnyAlert({
                    type: CommonAlertTypeEnum.trucksFailedArchived,
                    data: {
                        count: truckIds.length,
                        plateNumbers: invalidAssetsPlateNumbers,
                    },
                }),
            ),
        );
    }

    trucksRefreshChannel.emit({});

    for (const truckId of truckIds) {
        yield call(refreshTruckDetailsIfNeedSaga, companyId, truckId);
    }
}

function* assignTruckToContractSaga(action: AssignToContractRequestActionT): WrapGeneratorT<void> {
    const { changeContractRequest, companyId, contractName } = action;

    yield put(updateTrucksRequestBegin(companyId));

    let response: ReturnApiT<
        typeof brokerTranziitApi.assignTruckToContract | typeof carrierTranziitApi.assignTruckToContract
    > | null = null;
    if (checkIsDefaultCompanyId(companyId)) {
        response = yield carrierTranziitApi.assignTruckToContract(changeContractRequest);
    } else {
        response = yield brokerTranziitApi.assignTruckToContract(companyId, changeContractRequest);
    }

    const error = response?.[0];
    const result = response?.[1];

    if (error) {
        yield put(updateTrucksRequestError(error, companyId));
    } else {
        yield put(updateTrucksRequestSuccess(companyId));
    }

    const truckIds = changeContractRequest?.ids || [];
    const allCount = truckIds.length;
    const successCount = result?.changed || 0;
    const failedCount = allCount - successCount;

    const byId: ReReturnT<typeof selectTrucksByIds> = yield select(selectTrucksByIds(companyId));

    if (successCount === 1) {
        const truck = byId[truckIds[0]];

        yield put(
            addAlert(
                new CommonAnyAlert({
                    type: CommonAlertTypeEnum.truckSuccessfullyUpdatedContract,
                    data: {
                        plateNumber: truck?.plateNumber || '',
                        contractName,
                    },
                }),
            ),
        );
    }

    if (successCount > 1) {
        yield put(
            addAlert(
                new CommonAnyAlert({
                    type: CommonAlertTypeEnum.trucksSuccessfullyUpdatedContract,
                    data: {
                        count: successCount,
                        contractName,
                    },
                }),
            ),
        );
    }

    if (failedCount === 1) {
        const truck = byId[truckIds[0]];

        yield put(
            addAlert(
                new CommonAnyAlert({
                    type: CommonAlertTypeEnum.truckFailedUpdateContract,
                    data: {
                        plateNumber: truck?.plateNumber || '',
                        contractName,
                    },
                }),
            ),
        );
    }

    if (failedCount > 1) {
        const invalidAssetsPlateNumbers = truckIds.map((truckId) => {
            return byId[truckId]?.plateNumber || '';
        });

        yield put(
            addAlert(
                new CommonAnyAlert({
                    type: CommonAlertTypeEnum.trucksFailedUpdateContract,
                    data: {
                        count: failedCount,
                        plateNumbers: invalidAssetsPlateNumbers,
                        contractName,
                    },
                }),
            ),
        );
    }

    trucksRefreshChannel.emit({});

    for (const truckId of truckIds) {
        yield call(refreshTruckDetailsIfNeedSaga, companyId, truckId);
    }
}

function* changeRateTrucksSaga(action: ChangeRateTrucksRequestActionT): WrapGeneratorT<void> {
    const { changeRateVehiclesRequest, companyId } = action;

    yield put(updateTrucksRequestBegin(companyId));

    let response: ReturnApiT<
        typeof brokerTranziitApi.changeRateTrucks | typeof carrierTranziitApi.changeRateTrucks
    > | null = null;
    if (checkIsDefaultCompanyId(companyId)) {
        response = yield carrierTranziitApi.changeRateTrucks(changeRateVehiclesRequest);
    } else {
        response = yield brokerTranziitApi.changeRateTrucks(companyId, changeRateVehiclesRequest);
    }

    const error = response?.[0];
    const result = response?.[1];

    if (error) {
        yield put(updateTrucksRequestError(error, companyId));
    } else {
        yield put(updateTrucksRequestSuccess(companyId));
    }

    const truckIds = changeRateVehiclesRequest.ids || [];
    const allCount = truckIds.length;
    const successCount = result?.changed || 0;
    const failedCount = allCount - successCount;

    const byId: ReReturnT<typeof selectTrucksByIds> = yield select(selectTrucksByIds(companyId));

    if (successCount === 1) {
        const truck = byId[truckIds[0]];

        yield put(
            addAlert(
                new CommonAnyAlert({
                    type: CommonAlertTypeEnum.truckSuccessfullyUpdatedRate,
                    data: {
                        plateNumber: truck?.plateNumber || '',
                    },
                }),
            ),
        );
    }

    if (successCount > 1) {
        yield put(
            addAlert(
                new CommonAnyAlert({
                    type: CommonAlertTypeEnum.trucksSuccessfullyUpdatedRate,
                    data: {
                        count: successCount,
                    },
                }),
            ),
        );
    }

    if (failedCount === 1) {
        const truck = byId[truckIds[0]];

        yield put(
            addAlert(
                new CommonAnyAlert({
                    type: CommonAlertTypeEnum.truckFailedUpdateRate,
                    data: {
                        plateNumber: truck?.plateNumber || '',
                    },
                }),
            ),
        );
    }

    if (failedCount > 1) {
        const invalidAssetsPlateNumbers = truckIds.map((truckId) => {
            return byId[truckId]?.plateNumber || '';
        });

        yield put(
            addAlert(
                new CommonAnyAlert({
                    type: CommonAlertTypeEnum.trucksFailedUpdateRate,
                    data: {
                        count: failedCount,
                        plateNumbers: invalidAssetsPlateNumbers,
                    },
                }),
            ),
        );
    }

    trucksRefreshChannel.emit({});

    for (const truckId of truckIds) {
        yield call(refreshTruckDetailsIfNeedSaga, companyId, truckId);
    }
}

function* fetchTruckDetailsSaga(action: FetchTruckDetailsActionT): WrapGeneratorT<void> {
    const { truckId, companyId, options } = action;

    const detailsById = yield select(selectTrucksDetailsByIds(companyId));
    if (detailsById[truckId] && !options?.isForceUpdate) {
        return;
    }

    yield put(fetchTruckDetailsRequestBegin(companyId));

    let error: Error | null = null;
    let details: ApiTruckDetailsT | null = null;
    if (checkIsDefaultCompanyId(companyId)) {
        const result: ReturnApiT<typeof carrierTranziitApi.fetchTruckDetails> =
            yield carrierTranziitApi.fetchTruckDetails(truckId);
        const [resultError, data] = result;
        if (resultError) {
            error = resultError;
        }
        if (data) {
            details = {
                ...data,
                countryCode: data.countryCode,
            };
        }
    } else {
        const result: ReturnApiT<typeof brokerTranziitApi.fetchPartnerTruckDetails> =
            yield brokerTranziitApi.fetchPartnerTruckDetails(companyId, truckId);
        const [resultError, data] = result;
        if (resultError) {
            error = resultError;
        }
        if (data) {
            details = data;
        }
    }

    if (error) {
        yield put(fetchTruckDetailsRequestError(error, companyId));
        return;
    }

    if (details) {
        yield put(fetchTruckDetailsRequestSuccess(truckId, details, companyId));
    }
}

function* fetchTrucksStatsSaga(action: FetchTrucksStatsActionT): WrapGeneratorT<void> {
    const { companyId, options } = action;

    const fetchStatsRequestStatus: ReReturnT<typeof selectFetchTrucksStatsRequestStatus> = yield select(
        selectFetchTrucksStatsRequestStatus(companyId),
    );

    const isNeedRequest = checkNeedRequest(fetchStatsRequestStatus, options);
    if (!isNeedRequest) {
        return;
    }

    yield put(fetchTrucksStatsRequestBegin(companyId));

    let error: Error | null = null;
    let stats: CompanyTrucksStatsT | null = null;
    if (checkIsDefaultCompanyId(companyId)) {
        const result: ReturnApiT<typeof carrierTranziitApi.fetchTrucksActiveFleetCount> =
            yield carrierTranziitApi.fetchTrucksActiveFleetCount();
        const [resultError, data] = result;
        if (resultError) {
            error = resultError;
        }
        if (data) {
            stats = {
                actualTotalCount: data,
            };
        }
    } else {
        const result: ReturnApiT<typeof brokerTranziitApi.fetchTrucksActiveFleetCount> =
            yield brokerTranziitApi.fetchTrucksActiveFleetCount(companyId);
        const [resultError, data] = result;
        if (resultError) {
            error = resultError;
        }
        if (data) {
            stats = {
                actualTotalCount: data,
            };
        }
    }

    if (error) {
        yield put(fetchTrucksStatsRequestError(error, companyId));
        return;
    }

    yield put(fetchTrucksStatsRequestSuccess(stats, companyId));
}

function* updateTruckSaga(action: UpdateTruckActionT): WrapGeneratorT<void> {
    const { companyId, truckId, updateTruck } = action;

    yield put(updateTrucksRequestBegin(companyId));

    let result: ReturnApiT<typeof carrierTranziitApi.updateTruck | typeof brokerTranziitApi.updatePartnerTruck> | null =
        null;
    if (checkIsDefaultCompanyId(companyId)) {
        result = yield carrierTranziitApi.updateTruck(truckId, updateTruck);
    } else {
        result = yield brokerTranziitApi.updatePartnerTruck(companyId, truckId, updateTruck);
    }
    if (!result) {
        return;
    }
    const [error] = result;

    if (error) {
        yield put(updateTrucksRequestError(error, companyId));
    } else {
        yield put(updateTrucksRequestSuccess(companyId));
    }

    if (!error) {
        const detailsById: ReReturnT<typeof selectTrucksDetailsByIds> = yield select(
            selectTrucksDetailsByIds(companyId),
        );
        const truckDetails = detailsById[truckId as string];

        yield put(
            addAlert(
                new CommonAnyAlert({
                    type: CommonAlertTypeEnum.truckSuccessfullyEdited,
                    data: {
                        plateNumber: truckDetails?.plateNumber || '',
                    },
                }),
            ),
        );
    }

    yield put(fetchTruckDetails(truckId, companyId, { isForceUpdate: true }));
    trucksRefreshChannel.emit({});
}

function* importXLSTrucksSaga(action: ImportXLSTrucksActionT): WrapGeneratorT<void> {
    const { companyId, file } = action;

    yield put(importXLSTrucksRequestBegin(companyId));

    let result: ReturnApiT<
        typeof brokerTranziitApi.importPartnerXLSTrucks | typeof carrierTranziitApi.importXLSTrucks
    > | null = null;
    if (checkIsDefaultCompanyId(companyId)) {
        result = yield carrierTranziitApi.importXLSTrucks(file);
    } else {
        result = yield brokerTranziitApi.importPartnerXLSTrucks(companyId, file);
    }
    if (!result) {
        return;
    }
    const [error] = result;

    if (error) {
        yield put(importXLSTrucksRequestError(error, companyId));
    } else {
        yield put(importXLSTrucksRequestSuccess(companyId));
    }
}

function* exportXLSTrucksSaga(action: ExportXLSTrucksActionT): WrapGeneratorT<void> {
    const { companyId, query } = action;

    yield put(exportXLSTrucksRequestBegin(companyId));

    let result: ReturnApiT<
        typeof brokerTranziitApi.exportPartnerXLSTrucks | typeof carrierTranziitApi.exportXLSTrucks
    > | null = null;
    if (checkIsDefaultCompanyId(companyId)) {
        result = yield carrierTranziitApi.exportXLSTrucks(query);
    } else {
        result = yield brokerTranziitApi.exportPartnerXLSTrucks(companyId, query);
    }
    if (!result) {
        return;
    }
    const [error, data] = result;

    if (data) {
        downloadFile({
            name: `trucks-export-${moment().format('YYYY-MM-DD_HH:mm')}.xlsx`,
            data,
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
    }

    if (error) {
        yield put(exportXLSTrucksRequestError(error, companyId));
    } else {
        yield put(exportXLSTrucksRequestSuccess(companyId));
    }
}

function* trucksSaga(): WrapGeneratorT<void> {
    yield takeEvery(FETCH_TRUCKS_PAGE_REQUEST, fetchTrucksSaga);
    yield takeEvery(ADD_TRUCK_REQUEST, addTruckSaga);
    yield takeEvery(DELETE_TRUCKS_REQUEST, deleteTrucksSaga);
    yield takeEvery(ACTIVATION_TRUCKS_REQUEST, activateTrucksSaga);
    yield takeEvery(ARCHIVE_TRUCKS_REQUEST, archiveTrucksSaga);
    yield takeEvery(FETCH_TRUCK_DETAILS_REQUEST, fetchTruckDetailsSaga);
    yield takeEvery(UPDATE_TRUCK_REQUEST, updateTruckSaga);
    yield takeEvery(ASSIGN_TO_CONTRACT_TRUCKS_REQUEST, assignTruckToContractSaga);
    yield takeEvery(CHANGE_RATE_TRUCKS_REQUEST, changeRateTrucksSaga);
    yield takeEvery(FETCH_TRUCKS_STATS_REQUEST, fetchTrucksStatsSaga);
    yield takeEvery(IMPORT_XLS_TRUCKS_REQUEST, importXLSTrucksSaga);
    yield takeEvery(EXPORT_XLS_TRUCKS_REQUEST, exportXLSTrucksSaga);
}

export default trucksSaga;
